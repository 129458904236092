
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import Gallery from "./Gallery.vue";
import { GameInfoTypeOrError, GameInfoType } from "@/interfaces/GameInfoType";

@Options({
  components: {
    Gallery,
  },
  props: {
    game: Number,
  },
  watch: {
    $windowWidth: function(windowWidth) {
      if (windowWidth <= 650) {
        this.description = "tr";
      } else {
        this.description = "td";
      }
    },
    game: function(newVal) {
      this.imageID = 0;
      axios({
        method: "GET",
        url: "https://free-to-play-games-database.p.rapidapi.com/api/game",
        headers: {
          "x-rapidapi-key":
            "d19335f5f2mshd18d3d2f7703cebp124fe3jsn36f8a0db2672",
          "x-rapidapi-host": "free-to-play-games-database.p.rapidapi.com",
        },
        params: { id: newVal },
      })
        .then((res) => {
          this.gameInfo = res.data as GameInfoType;
          (this.$refs.top as HTMLDivElement)?.scrollTo(0, 0);
        })
        .catch(() => {
          this.gameInfo = "Error";
        });
    },
  },
})
export default class GameInfo extends Vue {
  game = 1;
  gameInfo: GameInfoTypeOrError = "Error";
  imageID = 0;
  description = "td";
}
