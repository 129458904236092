
import { Options, Vue } from "vue-class-component";
import { Screenshot } from "@/interfaces/GameInfoType";
import { PropType } from "vue";

@Options({
  props: {
    screenshots: Object as PropType<Screenshot>,
  },
})
export default class Gallery extends Vue {
  screenshots!: Screenshot[];
  imageID = 0;

  selectImage(img: number): void {
    this.imageID = img;
    (this.$refs.imageLarge as HTMLDivElement).scrollIntoView();
  }
}
