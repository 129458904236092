
import { Options, Vue } from "vue-class-component";
import Game from "./components/Game.vue";
import GameInfo from "./components/GameInfo.vue";
import GameType from "@/interfaces/GameType";
import Navbar from "./components/Navbar.vue";
import { GameInfoTypeOrError } from "@/interfaces/GameInfoType";

@Options({
  components: {
    Game,
    GameInfo,
    Navbar,
  },
})
export default class App extends Vue {
  gameList: GameType[] | "Error" = [];
  filteredList!: GameType[];
  gameInfo: GameInfoTypeOrError = "Error";
  infoBoxVisible = false;
  gameID = 0;

  // Update Event von Navbar.vue
  gameListUpdateEvent(value: GameType[]): void {
    this.gameList = value;
  }

  toggleInfobox(): void {
    this.infoBoxVisible = !this.infoBoxVisible;
  }

  getGameInfo(game: number): void {
    this.gameID = game;
    this.toggleInfobox();
  }
}
