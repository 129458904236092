
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import GameType from "@/interfaces/GameType";

@Options({
  data() {
    return {
      query: "",
      platform: "all",
      sortby: "relevance",
    };
  },
  watch: {
    gameList: function(newList) {
      this.gameList = newList;
      this.$emit("gameListUpdateEvent", newList);
    },
  },
})
export default class Navbar extends Vue {
  gameList: GameType[] | "Error" = [];
  allGames!: GameType[];
  querySortBy!: string;
  queryCategory!: string;
  queryPlatform!: string;

  query!: string;
  platform!: string;
  sortby!: string;

  created(): void {
    this.sendRequest();
  }

  sendRequest(): void {
    axios({
      method: "GET",
      url: "https://free-to-play-games-database.p.rapidapi.com/api/games",
      headers: {
        "x-rapidapi-key": "d19335f5f2mshd18d3d2f7703cebp124fe3jsn36f8a0db2672",
        "x-rapidapi-host": "free-to-play-games-database.p.rapidapi.com",
      },
      params: {
        "sort-by": this.querySortBy,
        platform: this.queryPlatform,
      },
    })
      .then((res) => {
        this.allGames = res.data;
        this.gameList = res.data;
      })
      .catch(() => {
        this.gameList = "Error";
      });
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  checkboxUpdate(): void {
    this.querySortBy = this.sortby;
    this.queryPlatform = this.platform;
    this.sendRequest();
  }

  search(): void {
    this.filterList(this.query);
  }

  filterList(query: string): void {
    let temp = this.allGames as GameType[];
    let filteredList = temp.filter((element: GameType) => {
      return element.title.toLowerCase().match(query.toLowerCase());
    });

    this.gameList = filteredList;
  }
}
